import _ from 'underscore';

import { I9nConstants, I9nSchemaBySystem } from '@biteinc/common';
import {
  BrinkApiServer,
  BrinkApiServerHelper,
  ComoTransactionIdSource,
  ComoTransactionIdSourceHelper,
  Currency,
  CustomerIdentifier,
  DefaultModBehavior,
  DefaultSelectionType,
  EigenApiServer,
  EigenApiServerHelper,
  Environment,
  FulfillmentMethodHelper,
  GivexApiServer,
  GivexApiServerHelper,
  IntegrationInstanceType,
  IntegrationInstanceTypeHelper,
  IntegrationSystem,
  LoyaltyAuthMethod,
  LoyaltyAuthMethodHelper,
  ModelType,
  ModGroupI9nItemRefSortFields,
  ModGroupI9nItemRefSortFieldsHelper,
  NcrAlohaOrderMode,
  OloCustomKeyOrderField,
  OloCustomKeyOrderFieldHelper,
  OloHandoffMode,
  OloHandoffModeHelper,
  OmnivorePosType,
  OmnivorePosTypeHelper,
  PinataApiServer,
  PinataApiServerHelper,
  PinataPayTransactionResult,
  PinataPayTransactionResultHelper,
  PunchhApiServer,
  PunchhOloPhoneSlug,
  PunchhOloSlug,
  PunchhPrinterBarcodeType,
  QsrAutomationsDepartmentKey,
  QsrAutomationsItemIdKey,
  QsrAutomationsMode,
  QsrAutomationsModeHelper,
  SendDescriptionToPos,
  SimphonyCheckNameField,
  SimphonyInformationLineField,
  SimphonyInformationLineFieldHelper,
  SyncMenuSectionImagesMode,
  WorldpayGiftCardType,
  WorldpayMarketCode,
} from '@biteinc/enums';
import { I9nHelper, StringHelper } from '@biteinc/helpers';
import { integrationSchema } from '@biteinc/schemas';

app.Integration = app.AbstractModel.extend({
  ModelName: 'integration',
  Schema: integrationSchema,
  Type: ModelType.Integration,

  initialize() {
    app.AbstractModel.prototype.initialize.apply(this, arguments);

    this._isPlaceholder = false;

    // For unsynced olo integrations, assume they have a pickup order type, so that the order type
    // dropdowns are populated automatically.
    // NOTE: This synced data won't be saved by the server, it's just here until the first sync.
    if (this.get('system') === IntegrationSystem.Olo && !this.get('syncedData')) {
      this.set({
        syncedData: {
          orderTypeById: {
            [OloHandoffMode.Pickup]: {
              _id: OloHandoffMode.Pickup,
              name: OloHandoffModeHelper.name(OloHandoffMode.Pickup),
            },
          },
        },
      });
    }
  },

  setIsPlaceholder() {
    this._isPlaceholder = true;
  },

  isPlaceholder() {
    return this._isPlaceholder;
  },

  defaults() {
    const defaults = {};
    if (app.vendorList && app.vendorList.hasBeenFetched() && app.vendorList.size()) {
      defaults.vendorId = app.vendorList.getDefaultVendor().id;
    }
    return defaults;
  },

  canBeSynced() {
    return I9nHelper.systemCanBeSynced(this.get('system'));
  },

  canHaveOrgLevelWebhookSettings() {
    switch (this.get('system')) {
      case IntegrationSystem.Olo:
        return true;
      default:
        return false;
    }
  },

  hasOrgLevelWebhookSettingsSetup() {
    return app.org.get('webhookSettings')?.find(({ system }) => {
      return system === this.get('system');
    });
  },

  displayName() {
    let name = '';
    if (app.location.supportsMultipleVendors()) {
      const vendor = app.vendorList.get(this.get('vendorId'));
      if (vendor) {
        name += `${vendor.displayName()}: `;
      }
    }
    name += I9nSchemaBySystem[this.get('system')].name;
    return name;
  },

  getAuthData() {
    return this.getSyncedProp('authData');
  },

  hasOAuthed() {
    return !!this.getAuthData();
  },

  requiresOAuth() {
    return !!this.getI9nSchema().requiresOAuth;
  },

  isEcommPayment() {
    return !!this.getI9nSchema().type === 'ecomm-payment';
  },

  canLoginToAccount() {
    return [IntegrationSystem.Stripe, IntegrationSystem.StripeTerminal].includes(
      this.get('system'),
    );
  },

  isMissingI9nLocationID() {
    return (
      (this.getI9nSchema().fields.locationID || {}).requiredForOperations && !this.getLocationName()
    );
  },

  isMissingUserProvidedFields() {
    const self = this;
    const i9nSchema = this.getI9nSchema();
    return _.any(_.keys(i9nSchema.fields), (field) => {
      const fieldSchema = i9nSchema.fields[field];
      return (
        fieldSchema.requiresUserInput && fieldSchema.isRequiredForValidation && !self.has(field)
      );
    });
  },

  isForDifferentLocationThan(integration) {
    const i9nSchema = this.getI9nSchema();
    const fieldNames = Object.keys(i9nSchema.fields).filter((field) => {
      return !!i9nSchema.fields[field].requiresValidationIfChanged;
    });
    return fieldNames.some((field) => {
      return this.get(field) !== integration.get(field);
    });
  },

  getLocationName() {
    if (this.get('locationID') && this.getSyncedProp('locationById')) {
      return Object.values(this.getSyncedProp('locationById')).find(({ _id }) => {
        return _id === this.get('locationID');
      })?.name;
    }
    return null;
  },

  getI9nDiningOption(fulfillmentMethod) {
    return _.findWhere(this.get('diningOptions'), { fulfillmentMethod });
  },

  destroy(opts, ...args) {
    if (!app.location || app.location.isLive()) {
      const confirmed = app.promptUserToTypeInConfirmation(
        `Please type in "DELETE" to confirm that you want to delete this integration from this live location.`,
        'DELETE',
      );
      if (!confirmed) {
        opts.error();
        return;
      }
    }
    app.AbstractModel.prototype.destroy.apply(this, [opts, ...args]);
  },

  isSandbox() {
    switch (this.get('system')) {
      case IntegrationSystem.Givex:
        switch (this.get('apiServer')) {
          case GivexApiServer.SANDBOX:
          case GivexApiServer.BETA:
            return true;
          default:
            return false;
        }
      case IntegrationSystem.ParBrink:
        return this.get('apiServer') === BrinkApiServer.Sandbox;
      case IntegrationSystem.Eigen:
        return (
          this.get('apiServer') === EigenApiServer.Sandbox1 ||
          this.get('apiServer') === EigenApiServer.Sandbox2
        );
      case IntegrationSystem.Archer:
        return this.get('username') === I9nConstants.ArcherTestUsername;
      default:
        return this.get('instanceType') === IntegrationInstanceType.Sandbox;
    }
  },

  // NOTE: please remember to mirror these requirements in
  // db-models/src/services/location_service.ts::getNeededActions
  getNeededActions() {
    if (this.requiresOAuth() && !this.hasOAuthed()) {
      return [`Connect your ${this.displayName()} account to continue.`];
    }
    if (this.isMissingI9nLocationID()) {
      return ['Please select a location.'];
    }

    const neededActions = [];

    if (this.get('disable')) {
      neededActions.push('Temporarily Disabled');
    }
    if (this.get('disableSync')) {
      neededActions.push('Sync is disabled.');
    }
    if (this.get('skipNonSyncOperations')) {
      neededActions.push('Non-sync operations will be skipped.');
    }
    if (
      this.has('instanceType') ||
      [IntegrationSystem.ParBrink, IntegrationSystem.ParBrinkLoyalty].includes(this.get('system'))
    ) {
      if (this.isSandbox() && app.location.isLive()) {
        neededActions.push('Sandbox integration in a live location');
      }
    }

    const { fields, diningOptionFields, diningOptionConditions } = this.getI9nSchema();

    const missingFields = [];
    _.each(fields, (fieldSchema, field) => {
      const fieldName = fieldSchema.displayName || field;
      if (fieldSchema.requiredForOperations) {
        if (fieldSchema.conditions?.length) {
          const conditionsMet = fieldSchema.conditions.every((condition) => {
            const conditionField = condition.field;
            if (condition.exists) {
              return !!this.get(conditionField);
            }
            return condition.values?.includes(this.get(conditionField));
          });
          if (!conditionsMet) {
            return;
          }
        }
        switch (fieldSchema.type) {
          case 'array':
            if (!this.hasArr(field)) {
              missingFields.push(fieldName);
            }
            break;
          default:
            if (!this.get(field)) {
              missingFields.push(fieldName);
            }
        }
      }
    });
    if (missingFields.length) {
      neededActions.push(`Please select ${missingFields.join(', ')}`);
    }

    if (
      app.location.getLoyaltyI9nSchema()?.requiresDiscountId &&
      this.getI9nSchema().syncsDiscounts &&
      !this.get('loyaltyDiscountId')
    ) {
      neededActions.push('Please select a Loyalty Discount on the POS.');
    }

    switch (this.get('system')) {
      case IntegrationSystem.Twilio:
        if (!this.hasStr('senderMessageGroupId') && !this.hasStr('senderPhoneNumberId')) {
          neededActions.push(
            `Please select one of ${fields.senderMessageGroupId.displayName} or ${fields.senderPhoneNumberId.displayName}`,
          );
        }
        break;
    }

    const diningOptions = app.location.getAllDiningOptions();
    const requireMemoItem =
      this.getI9nSchema().type === 'pos' &&
      this.getI9nSchema().fields.memoItemID &&
      _.any(diningOptions, (diningOption) => {
        const hasVehicleIdentifier = _.findWhere(diningOption.customerIdentifierOptions, {
          customerIdentifier: CustomerIdentifier.VehicleDescription,
        });
        return (
          hasVehicleIdentifier ||
          FulfillmentMethodHelper.isAnOutpost(diningOption.fulfillmentMethod)
        );
      });
    if (requireMemoItem && !this.get('memoItemID')) {
      neededActions.push('Please select a memo item.');
    }

    const singleUseItemById = this.getSyncedProp('singleUseItemById');
    const utensilsItemID = this.get('utensilsItemID');
    if (
      this.getI9nSchema().system === IntegrationSystem.Olo &&
      singleUseItemById &&
      Object.keys(singleUseItemById).length
    ) {
      if (!utensilsItemID) {
        neededActions.push('Please select a utensils item');
      }
      if (utensilsItemID && !singleUseItemById[utensilsItemID]) {
        neededActions.push('The selected utensils item is no longer found on the POS.');
      }
    }

    const satisfiesDiningOptionConditions = (diningOptionConditions ?? []).every((condition) => {
      if (condition.exists) {
        return !!this.get(condition.field);
      }
      return condition.values.includes(this.get(condition.field));
    });

    if (diningOptionFields && satisfiesDiningOptionConditions) {
      _.each(diningOptions, ({ fulfillmentMethod }) => {
        if (
          !FulfillmentMethodHelper.isKiosk(fulfillmentMethod) &&
          !app.location.get('flashEnabled')
        ) {
          return;
        }

        const diningOption = app.location.getDiningOption(fulfillmentMethod);
        const diningOptionName = `"${app.location.getDiningOptionName(
          fulfillmentMethod,
        )}" dining option`;
        const i9nDiningOption = this.getI9nDiningOption(fulfillmentMethod);
        if (!i9nDiningOption) {
          neededActions.push(`Please add a ${diningOptionName}.`);
          return;
        }

        const missingDiningOptionFields = [];
        _.each(diningOptionFields, (fieldSchema, field) => {
          if (!fieldSchema.requiredForOperations) {
            return;
          }
          if (!diningOption.isEnabled) {
            return;
          }

          switch (field) {
            case 'interacTenderTypeID':
              // Don't require the interac tender unless we are in Canada
              if (app.locationSettings.get('currencyCode') !== Currency.CAD) {
                break;
              }
            // fallthrough
            case 'visaTenderTypeID':
            case 'masterCardTenderTypeID':
            case 'discoverTenderTypeID':
            case 'amExTenderTypeID':
            case 'otherTenderTypeID':
            case 'fullyDiscountedOrderTenderTypeID':
            case 'cashTenderTypeID':
            case 'giftCardTenderTypeID':
            case 'tenderTypeID':
            case 'taxExemptTenderTypeID': {
              const tenderTypeById = this.getSyncedProp('tenderTypeById') || {};
              if (!i9nDiningOption[field] || !tenderTypeById[i9nDiningOption[field]]) {
                missingDiningOptionFields.push(fieldSchema.displayName || field);
              }
              break;
            }
            case 'kdsDeviceIds': {
              if (!i9nDiningOption[field]?.length) {
                // Fresh Kds deviceIds is an array and can be empty
                missingDiningOptionFields.push(fieldSchema.displayName || field);
              }
              break;
            }
            case 'orderTypeID': {
              // NCR Aloha uses a predefined enum for it's orderTypeID
              const system = this.get('system');
              if (system === IntegrationSystem.NcrAloha) {
                break;
              }
              // Infor and IQTouch doesn't sync orderTypeById and instead uses a custom input
              // for it's orderTypeID
              if (
                (IntegrationSystem.Infor === system || IntegrationSystem.IQTouch === system) &&
                i9nDiningOption[field]
              ) {
                break;
              }
              const orderTypeById = this.getSyncedProp('orderTypeById') || {};
              if (
                [undefined, null].includes(i9nDiningOption[field]) ||
                [undefined, null].includes(orderTypeById[i9nDiningOption[field]])
              ) {
                missingDiningOptionFields.push(fieldSchema.displayName || field);
              }
              break;
            }
            default:
              if ([undefined, null].includes(i9nDiningOption[field])) {
                missingDiningOptionFields.push(fieldSchema.displayName || field);
              }
              break;
          }
        });
        if (missingDiningOptionFields.length) {
          neededActions.push(
            `Please select ${missingDiningOptionFields.join(', ')} in the ${diningOptionName}.`,
          );
        }
      });
    }

    return neededActions;
  },

  buildOAuthUrl() {
    let url;
    let params;
    switch (this.get('system')) {
      case IntegrationSystem.SquarePos:
        url = 'https://connect.squareup.com/oauth2/authorize';
        params = {
          client_id: app.data.SQUARE_POS_APP_ID,
          scope: [
            'PAYMENTS_WRITE',
            'PAYMENTS_READ',
            'MERCHANT_PROFILE_READ',
            'ORDERS_WRITE',
            'ITEMS_READ',
            'INVENTORY_READ', // will need for 86ing
          ].join(' '),
          state: JSON.stringify({
            url: window.location.toString(),
            sessionUserId: app.data.sessionUser._id,
            locationId: app.location.id,
            integrationId: this.id,
            env: app.data.APP_ENV,
            // uniqueUnguessable to prevent against CSRF
            // https://auth0.com/docs/protocols/state-parameters
            uniqueUnguessable: StringHelper.randomHexString(16),
          }),
          // even if square user has a session, force auth with user/pass in square form
          session: app.data.APP_ENV !== Environment.PROD,
        };
        break;
      case IntegrationSystem.Stripe:
      case IntegrationSystem.StripeTerminal: {
        const stripeRedirectUri =
          `${window.location.origin}/redirect/stripe?` +
          // may not need to double encode this
          `&url=${window.location.pathname}#integrations`;
        url = 'https://connect.stripe.com/express/oauth/authorize';
        params = {
          redirect_uri: stripeRedirectUri,
          client_id: app.data.STRIPE_CLIENT_ID,
          'stripe_user[business_type]': 'company',
          'suggested_capabilities[]': 'card_payments',
          'stripe_user[email]': app.location.getStripeEmail(this.get('vendorId')),
          state: JSON.stringify({
            url: window.location.toString(),
            locationId: app.location.id,
            integrationId: this.id,
            env: app.data.APP_ENV,
          }),
        };
      }
    }
    const paramPairs = _.map(params, (value, key) => {
      return `${key}=${encodeURIComponent(value)}`;
    });
    url += `?${paramPairs.join('&')}`;
    return url;
  },

  isPosI9n() {
    return this.getI9nSchema().type === 'pos';
  },

  isFulfillmentI9n() {
    return this.getI9nSchema().type === 'fulfillment';
  },

  /**
   * @returns {import('@biteinc/common').I9nSchema}
   */
  getI9nSchema() {
    return this.i9nSchema ? this.i9nSchema : I9nSchemaBySystem[this.get('system')];
  },

  setI9nSchema(i9nSchema) {
    this.i9nSchema = i9nSchema;
  },

  isLoyaltyI9n() {
    return this.getI9nSchema().type === 'loyalty';
  },

  isTwilio() {
    return this.getI9nSchema().system === IntegrationSystem.Twilio;
  },

  getSyncedProp(property) {
    return (this.get('syncedData') || {})[property];
  },

  hasSyncedProp(property) {
    return _.has(this.get('syncedData') || {}, property);
  },

  supportsPayingWithCash() {
    return !!this.getI9nSchema().supportsPayingWithCash;
  },

  supportsTips() {
    if (this.getSyncedProp('supportsTips')) {
      return true;
    }
    const systemsWithTippableTender = [
      IntegrationSystem.Omnivore,
      IntegrationSystem.ParBrink,
      IntegrationSystem.Infor,
      IntegrationSystem.Simphony,
    ];
    if (_.contains(systemsWithTippableTender, this.get('system'))) {
      if (this.has('tenderTypeID') && this.getSyncedProp('tenderTypeById')) {
        const tenderType = this.getSyncedProp('tenderTypeById')[this.get('tenderTypeID')] || {};
        return tenderType.supportsTips;
      }
      // Make sure all tenders in dining options support tips
      return _.all(this.get('diningOptions'), (diningOption) => {
        return _.all(I9nHelper.i9nTenderTypeFields, (tenderKey) => {
          // Only require tender fields that are in the i9n schemas diningOptionFields
          if (
            this.getI9nSchema().diningOptionFields &&
            !this.getI9nSchema().diningOptionFields[tenderKey]
          ) {
            return true;
          }
          const tenderPosId = diningOption[tenderKey];
          if (!tenderPosId) {
            // some tender has not been set, cannot guarantee that tips are enabled
            return false;
          }
          return this.getSyncedProp('tenderTypeById')?.[tenderPosId]?.supportsTips;
        });
      });
    }
    return !!this.getI9nSchema().supportsTips;
  },

  supportsItemRecipient() {
    if (this.getSyncedProp('supportsItemRecipient')) {
      return true;
    }
    return !!this.getI9nSchema().supportsItemRecipient;
  },

  syncsModelsWithType(modelType) {
    const i9nSchema = this.getI9nSchema();
    switch (modelType) {
      case ModelType.Mod:
      case ModelType.ModGroup:
      case ModelType.MenuItem:
        return true;
      case ModelType.MenuSection:
        return !!i9nSchema.syncsSections && !!this.get('syncSections');
      case ModelType.MenuStructure:
        return !!i9nSchema.syncsMenuStructures && !!this.get('syncMenuStructures');
      case ModelType.TaxProfile:
        return !!i9nSchema.syncsTaxes;
      case ModelType.OpenHoursTimetable:
        return !!i9nSchema.syncsOpenHoursTimetables;
      case ModelType.OpenHoursOverride:
        return !!i9nSchema.syncsOpenHoursOverrides;
      default:
        return false;
    }
  },

  syncI9n(callback) {
    app.QueueHelper.makeRequest('POST', `${this.url()}/sync`, null, true, callback);
  },

  getLoginLink(callback) {
    app.makeRequest(
      'POST',
      `${this.url()}/loginLink`,
      null,
      (response) => {
        callback(null, response);
      },
      (error) => {
        callback(error);
      },
    );
  },

  getFieldCollection(field, _subProperty, _includeAllValues, _keyModel) {
    switch (field) {
      case 'authMethods':
        const i9nSchema = this.getI9nSchema();
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LoyaltyAuthMethod,
          nameGenerator: LoyaltyAuthMethodHelper.name,
          values: i9nSchema.supportedAuthMethods,
        });
      case 'flashAuthMethod':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: LoyaltyAuthMethod,
          nameGenerator: LoyaltyAuthMethodHelper.name,
        });
      case 'instanceType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: IntegrationInstanceType,
          nameGenerator: IntegrationInstanceTypeHelper.name,
        });
      case 'locationID':
        if (this.getSyncedProp('locationById')) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('locationById'),
          });
        }
        break;
      case 'visaTenderTypeID':
      case 'masterCardTenderTypeID':
      case 'discoverTenderTypeID':
      case 'amExTenderTypeID':
      case 'interacTenderTypeID':
      case 'otherTenderTypeID':
      case 'fullyDiscountedOrderTenderTypeID':
      case 'cashTenderTypeID':
      case 'giftCardTenderTypeID':
      case 'tenderTypeID':
      case 'taxExemptTenderTypeID':
        if (this.getSyncedProp('tenderTypeById')) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('tenderTypeById'),
            ModelClass: app.Integration.TippableTenderType,
            sort: app.AbstractCollection.SortOptions.NAME,
          });
        }
        break;
      case 'kdsDeviceIds':
        if (!this.get('freshKdsUseOnPremConnection') && this.getSyncedProp('freshKdsDeviceById')) {
          const freshKdsLocationId = this.get('locationID');
          if (!freshKdsLocationId) {
            // if no location is selected, return empty collection
          }
          if (freshKdsLocationId) {
            // we should only show KDS devices for the selected location
            const freshKdsDevicesForLocationId = _.filter(
              this.getSyncedProp('freshKdsDeviceById'),
              (kdsDevice) => {
                return kdsDevice.locationId === freshKdsLocationId;
              },
            );
            return app.AbstractCollection.createFromEnum({
              schema: freshKdsDevicesForLocationId,
            });
          }
        }
        if (this.get('freshKdsUseOnPremConnection') && this.get('freshKdsOnPremDevices').length) {
          const freshKdsOnPremDevices = this.get('freshKdsOnPremDevices').map((device) => {
            return {
              _id: device.deviceIpAddress,
              name: `${device.deviceIpAddress}:${device.devicePort} - ${device.deviceName}`,
            };
          });
          return app.AbstractCollection.createFromEnum({
            schema: freshKdsOnPremDevices,
          });
        }
        break;
      case 'fulfillmentStationIds': {
        return app.AbstractCollection.createFromEnum({
          schema: app.site.get('fulfillmentStations').filter((station) => station.isEnabled),
          sort: app.AbstractCollection.SortOptions.NAME,
        });
      }
      case 'orderTypeID':
        if (this.getSyncedProp('orderTypeById')) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('orderTypeById'),
            sort: app.AbstractCollection.SortOptions.NAME,
          });
        }
        if (this.get('system') === IntegrationSystem.NcrAloha) {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: NcrAlohaOrderMode,
            nameGenerator: (orderMode) => {
              switch (orderMode) {
                case NcrAlohaOrderMode.CallIn:
                  return 'Call In';
                case NcrAlohaOrderMode.WalkIn:
                  return 'Walk In';
                case NcrAlohaOrderMode.Web:
                  return 'Web';
                case NcrAlohaOrderMode.Delivery:
                  return 'Delivery';
                case NcrAlohaOrderMode.Curbside:
                  return 'Curbside';
                case NcrAlohaOrderMode.EatIn:
                  return 'Eat In';
                case NcrAlohaOrderMode.DriveThru:
                  return 'Drive Through';
                case NcrAlohaOrderMode.Catering:
                  return 'Catering';
                case NcrAlohaOrderMode.FaxedIn:
                  return 'Faxed In';
                case NcrAlohaOrderMode.None:
                  return 'None';
              }
            },
            sort: app.AbstractCollection.SortOptions.NAME,
          });
        }
        break;
      case 'serviceChargeId':
        if (this.getSyncedProp('serviceChargeById')) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('serviceChargeById'),
          });
        }
        break;
      case 'utensilsItemID':
        if (this.getSyncedProp('singleUseItemById')) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('singleUseItemById'),
            ModelClass: app.MenuItem,
          });
        }
        break;
      case 'memoItemID': {
        if (this.getSyncedProp('memoItemById')) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('memoItemById'),
            ModelClass: app.MenuItem, // this make this list searchable
          });
        }
        break;
      }
      case 'vendorId':
        return app.vendorList;
      case 'sendDescriptionToPos':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: SendDescriptionToPos,
          nameGenerator: (category) => {
            switch (category) {
              case SendDescriptionToPos.Never:
                return 'Never';
              case SendDescriptionToPos.Always:
                return 'Always';
              case SendDescriptionToPos.BarcodeScan:
                return 'When scanned from a barcode';
              default:
                throw new Error(`unrecognized send description option value: ${category}`);
            }
          },
        });
      case 'loyaltyDiscountId':
        if (this.getSyncedProp('discountById')) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('discountById'),
          });
        }
        break;
      case 'pinataApiServer':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: PinataApiServer,
          nameGenerator: PinataApiServerHelper.name,
        });
      case 'punchhOloProvider':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: PunchhOloSlug,
          nameGenerator: (slug) => {
            return slug;
          },
        });
      case 'punchhOloPhoneProvider':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: PunchhOloPhoneSlug,
          nameGenerator: (slug) => {
            return slug;
          },
        });
      case 'syncMenuSectionImages':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: SyncMenuSectionImagesMode,
          nameGenerator: (mode) => {
            switch (mode) {
              case SyncMenuSectionImagesMode.DoNotSync:
                return 'Do Not Sync';
              case SyncMenuSectionImagesMode.SyncAsHeaderImage:
                return 'Sync as Header Image';
              case SyncMenuSectionImagesMode.SyncAsPageNavImage:
                return 'Sync as Page Nav Image';
            }
          },
        });
      case 'orderField':
        if (_subProperty === 'customFields') {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: OloCustomKeyOrderField,
            nameGenerator: OloCustomKeyOrderFieldHelper.name,
          });
        }
        break;
      case 'modGroupModSortPriority':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ModGroupI9nItemRefSortFields,
          nameGenerator: ModGroupI9nItemRefSortFieldsHelper.name,
        });
      case 'printerBarcodeType':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: PunchhPrinterBarcodeType,
          nameGenerator: (value) => {
            switch (value) {
              case PunchhPrinterBarcodeType.QR:
                return 'QR';
              case PunchhPrinterBarcodeType.BarcodeMap1Short:
                return 'Barcode (Map 1 Short)';
              case PunchhPrinterBarcodeType.BarcodeMap1:
                return 'Barcode (Map 1)';
              case PunchhPrinterBarcodeType.BarcodeMap3:
                return 'Barcode (Map 3)';
              case PunchhPrinterBarcodeType.None:
                return 'None';
            }
          },
        });
      case 'transactionIdSource':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: ComoTransactionIdSource,
          nameGenerator: ComoTransactionIdSourceHelper.name,
        });
      case 'discountIds':
        if (
          _subProperty === 'brinkDiscountsByCardId' &&
          this.getSyncedProp('loyaltyDiscountById')
        ) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('loyaltyDiscountById'),
            sort: app.AbstractCollection.SortOptions.NAME,
          });
        }
        break;
      case 'loyaltyRewardId':
        const loyaltyI9n = app.location.getFullLoyaltyI9n();
        const discounts = loyaltyI9n?.getSyncedProp('discountById');
        if (_subProperty === 'brinkDiscountIdsMapping' && this.getSyncedProp('discountById')) {
          return app.AbstractCollection.createFromEnum({
            schema: discounts,
            sort: app.AbstractCollection.SortOptions.NAME,
          });
        }
        break;
      case 'posDiscountId':
        if (_subProperty === 'brinkDiscountIdsMapping' && this.getSyncedProp('discountById')) {
          return app.AbstractCollection.createFromEnum({
            schema: this.getSyncedProp('discountById'),
            sort: app.AbstractCollection.SortOptions.NAME,
          });
        }
        break;
      case 'pinataPayTransactionResult':
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: PinataPayTransactionResult,
          nameGenerator: PinataPayTransactionResultHelper.name,
        });
    }

    if (this.get('system') === IntegrationSystem.Omnivore) {
      switch (field) {
        case 'posType':
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: OmnivorePosType,
            nameGenerator: OmnivorePosTypeHelper.name,
            sort: app.AbstractCollection.SortOptions.NAME,
          });
        case 'employeeID':
          if (this.getSyncedProp('employeeById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('employeeById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'revenueCenterID':
          if (this.getSyncedProp('revenueCenterById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('revenueCenterById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'subModIdForDeselectedMods':
          if (this.getSyncedProp('deselectSubModById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('deselectSubModById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
      }
    } else if (
      [IntegrationSystem.Worldpay, IntegrationSystem.WorldpayStoredValue].includes(
        this.get('system'),
      )
    ) {
      if ('marketCode' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: WorldpayMarketCode,
          nameGenerator: (marketCode) => {
            switch (marketCode) {
              case WorldpayMarketCode.FoodRestaurant:
                return 'Food Restaurant';
              case WorldpayMarketCode.Retail:
                return 'Retail';
            }
          },
        });
      }
      if ('giftCardType' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: WorldpayGiftCardType,
          nameGenerator: (giftCardType) => {
            switch (giftCardType) {
              case WorldpayGiftCardType.Vantiv:
                return 'Vantiv';
              case WorldpayGiftCardType.StoreCard:
                return 'Store Card';
            }
          },
        });
      }
    } else if (this.get('system') === IntegrationSystem.ParBrink) {
      if ('apiServer' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: BrinkApiServer,
          nameGenerator: BrinkApiServerHelper.name,
        });
      }
      if ('alternateMenuId' === field && this.getSyncedProp('menuById')) {
        return app.AbstractCollection.createFromEnum({ schema: this.getSyncedProp('menuById') });
      }
      if ('sendDefaultModBehavior' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: DefaultModBehavior,
          nameGenerator: (behavior) => {
            switch (behavior) {
              case DefaultModBehavior.Send:
                return 'Send';
              case DefaultModBehavior.DoNotSend:
                return 'Do Not Send';
              case DefaultModBehavior.UseSystemDefault:
                return 'Use System Default';
            }
          },
        });
      }
    } else if (this.get('system') === IntegrationSystem.Eigen) {
      if ('apiServer' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: EigenApiServer,
          nameGenerator: EigenApiServerHelper.name,
        });
      }
    } else if (this.get('system') === IntegrationSystem.Revel) {
      if ('menuIds' === field && this.getSyncedProp('menuById')) {
        return app.AbstractCollection.createFromEnum({
          schema: this.getSyncedProp('menuById'),
          sort: app.AbstractCollection.SortOptions.NAME,
        });
      }
    } else if (this.get('system') === IntegrationSystem.SpotOn) {
      if ('menuIds' === field && this.getSyncedProp('menuById')) {
        return app.AbstractCollection.createFromEnum({
          schema: this.getSyncedProp('menuById'),
          sort: app.AbstractCollection.SortOptions.NAME,
        });
      }
    } else if (this.get('system') === IntegrationSystem.IQTouch) {
      if ('menuIds' === field && this.getSyncedProp('menuById')) {
        return app.AbstractCollection.createFromEnum({
          schema: this.getSyncedProp('menuById'),
          sort: app.AbstractCollection.SortOptions.NAME,
        });
      }
    } else if (this.get('system') === IntegrationSystem.Givex) {
      if ('apiServer' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: GivexApiServer,
          nameGenerator: GivexApiServerHelper.name,
        });
      }
    } else if (
      this.get('system') === IntegrationSystem.LevelUp ||
      this.get('system') === IntegrationSystem.ChoptLoyalty
    ) {
      const posI9n = app.location.getFullPosI9n();
      switch (field) {
        case 'posTenderTypeID':
        case 'posGiftCardTenderTypeID':
          if (
            posI9n.getI9nSchema().system !== IntegrationSystem.Toast &&
            posI9n.getSyncedProp('tenderTypeById')
          ) {
            return app.AbstractCollection.createFromEnum({
              schema: posI9n.getSyncedProp('tenderTypeById'),
              ModelClass: app.Integration.TippableTenderType,
            });
          }
          // Toast integrations store tenders in alternatePaymentTypeById
          if (
            posI9n.getI9nSchema().system === IntegrationSystem.Toast &&
            posI9n.getSyncedProp('alternatePaymentTypeById')
          ) {
            const schema = {};

            Object.values(posI9n.getSyncedProp('alternatePaymentTypeById')).forEach(
              ({ i9nId, name, posId }) => {
                schema[i9nId] = {
                  _id: i9nId,
                  posName: name,
                  tenderType: posId,
                  supportsTips: true,
                };
              },
            );

            return app.AbstractCollection.createFromEnum({
              schema,
              ModelClass: app.Integration.TippableTenderType,
            });
          }
          break;
      }
    } else if (
      this.get('system') === IntegrationSystem.Punchh ||
      this.get('system') === IntegrationSystem.PunchhOlo ||
      this.get('system') === IntegrationSystem.PinataLoyalty ||
      this.get('system') === IntegrationSystem.Como ||
      this.get('system') === IntegrationSystem.Lunchbox ||
      this.get('system') === IntegrationSystem.PaytronixCompCard ||
      this.get('system') === IntegrationSystem.PaytronixLoyalty ||
      this.get('system') === IntegrationSystem.PaytronixOlo ||
      this.get('system') === IntegrationSystem.Incentivio
    ) {
      if ('apiServer' === field) {
        if (this.get('system') === IntegrationSystem.PinataLoyalty) {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: PinataApiServer,
            nameGenerator: PinataApiServerHelper.name,
          });
        }
        if ([IntegrationSystem.Punchh, IntegrationSystem.PunchhOlo].includes(this.get('system'))) {
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: PunchhApiServer,
            nameGenerator: (apiServer) => {
              switch (apiServer) {
                case PunchhApiServer.Punchh:
                  return 'punchh.com';
                case PunchhApiServer.UsWestPunchh:
                  return 'us-west.punchh.com';
              }
            },
          });
        }
      }

      if (
        'itemKey' === field &&
        [
          IntegrationSystem.PaytronixCompCard,
          IntegrationSystem.PaytronixLoyalty,
          IntegrationSystem.PaytronixOlo,
        ].includes(this.get('system'))
      ) {
        return app.AbstractCollection.createFromArray({
          values: ['posId', 'i9nId', 'barcode'],
          nameGenerator: (value) => {
            if (value === 'barcode') {
              return 'Barcode';
            }

            const posSystem = app.location.getPosI9nSchema()?.system;

            switch (posSystem) {
              case IntegrationSystem.Omnivore:
                return value === 'posId' ? 'posId' : 'omnivoreId';
              case IntegrationSystem.Olo:
                return value === 'posId' ? 'POS ID' : 'OlO ID';
              case IntegrationSystem.ParBrink:
                return value === 'posId' ? 'Item ID' : 'MenuItem ID';
              default:
                return value;
            }
          },
        });
      }
    } else if (this.get('system') === IntegrationSystem.Toast) {
      switch (field) {
        case 'menuIds':
          if (this.getSyncedProp('menuById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('menuById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'alternatePaymentTypeId':
          if (this.getSyncedProp('alternatePaymentTypeById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('alternatePaymentTypeById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'revenueCenterId':
          if (this.getSyncedProp('revenueCenterById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('revenueCenterById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'automaticDiscounts':
          if (this.getSyncedProp('fixedDiscountById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('fixedDiscountById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
      }
    } else if (this.get('system') === IntegrationSystem.Simphony) {
      switch (field) {
        case 'menuId':
          if (this.getSyncedProp('menuById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('menuById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'revenueCenterId':
          if (this.getSyncedProp('revenueCenterById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('revenueCenterById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'orderChannelID':
          if (this.getSyncedProp('orderChannelById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('orderChannelById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'cashTenderTypeID':
          if (this.getSyncedProp('tenderTypeById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('tenderTypeById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'simphonyWeightedFamilyGroupID':
          if (this.getSyncedProp('simphonyFamilyGroupById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('simphonyFamilyGroupById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'checkNameField':
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: SimphonyCheckNameField,
            nameGenerator: (simphonyCheckNameField) => {
              switch (simphonyCheckNameField) {
                case SimphonyCheckNameField.None:
                  return 'None';
                case SimphonyCheckNameField.FirstNameTemplate:
                  return 'First Name Template';
                case SimphonyCheckNameField.LastNameTemplate:
                  return 'Last Name Template';
                case SimphonyCheckNameField.TableNumber:
                  return 'Table Number';
              }
            },
          });
        case 'applyDeselectedBehaviorTo':
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: DefaultSelectionType,
            nameGenerator: (selectionType) => {
              switch (selectionType) {
                case DefaultSelectionType.None:
                  return 'None';
                case DefaultSelectionType.BiteOrPos:
                  return 'Bite or POS';
                case DefaultSelectionType.Bite:
                  return 'Bite';
                case DefaultSelectionType.Pos:
                  return 'POS';
              }
            },
          });
        case 'modIdToAddAboveDeselectedMods':
          if (this.getSyncedProp('modById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('modById'),
              sort: app.AbstractCollection.SortOptions.NAME,
            });
          }
          break;
        case 'menuSectionSluNumber':
          return app.AbstractCollection.createFromIntValues(1, 9);
        case 'orderFieldsForInformationLines':
          return app.AbstractCollection.createFromTsEnum({
            tsEnum: SimphonyInformationLineField,
            nameGenerator: SimphonyInformationLineFieldHelper.name,
          });
        case 'tenderIdsThatSupportTips':
          if (this.getSyncedProp('tenderTypeById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('tenderTypeById'),
            });
          }
      }
    } else if (this.get('system') === IntegrationSystem.Qu) {
      switch (field) {
        case 'orderChannelID':
          if (this.getSyncedProp('orderChannelById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('orderChannelById'),
            });
          }
          break;
        case 'orderTypeID':
          if (this.getSyncedProp('orderTypeById')) {
            return app.AbstractCollection.createFromEnum({
              schema: this.getSyncedProp('orderTypeById'),
            });
          }
          break;
      }
    } else if (this.get('system') === IntegrationSystem.Twilio) {
      if ('senderPhoneNumberId' === field && this.getSyncedProp('phoneNumberById')) {
        return app.AbstractCollection.createFromEnum({
          schema: this.getSyncedProp('phoneNumberById'),
        });
      }
    } else if (
      [
        IntegrationSystem.PinataPos,
        IntegrationSystem.PinataLoyalty,
        IntegrationSystem.PinataNotifier,
        IntegrationSystem.PinataStoredValue,
        IntegrationSystem.PinataFulfillment,
      ].includes(this.get('system'))
    ) {
      if ('apiServer' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: PinataApiServer,
          nameGenerator: PinataApiServerHelper.name,
        });
      }
    } else if (this.get('system') === IntegrationSystem.QsrAutomations) {
      if ('qsrAutomationsMode' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: QsrAutomationsMode,
          nameGenerator: QsrAutomationsModeHelper.name,
        });
      }
      if ('qsrAutomationsItemIdKey' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: QsrAutomationsItemIdKey,
          nameGenerator: (itemIdKey) => {
            switch (itemIdKey) {
              case QsrAutomationsItemIdKey.ItemBiteId:
                return 'Item/Mod Bite ID';
              case QsrAutomationsItemIdKey.ItemBarcode:
                return 'Item/Mod Barcode';
            }
          },
        });
      }
      if ('qsrAutomationsDepartmentKey' === field) {
        return app.AbstractCollection.createFromTsEnum({
          tsEnum: QsrAutomationsDepartmentKey,
          nameGenerator: (departmentKey) => {
            switch (departmentKey) {
              case QsrAutomationsDepartmentKey.SectionBiteId:
                return 'Section Bite ID';
              case QsrAutomationsDepartmentKey.SectionInternalName:
                return 'Section Internal Name';
            }
          },
        });
      }
    } else if (this.get('system') === IntegrationSystem.Thanx) {
      if ('merchantId' === field && this.getSyncedProp('merchantById')) {
        return app.AbstractCollection.createFromEnum({
          schema: this.getSyncedProp('merchantById'),
        });
      }
      if ('thanxLocationID' === field && this.getSyncedProp('locationById')) {
        return app.AbstractCollection.createFromEnum({
          schema: this.getSyncedProp('locationById'),
        });
      }
    }
    return new app.AbstractCollection();
  },

  canBeManagedByUser() {
    return (
      app.sessionUser.canManageFullI9ns() ||
      (app.sessionUser.canManageSomeI9ns() &&
        (this.isPosI9n() || this.isLoyaltyI9n() || this.isTwilio() || this.isFulfillmentI9n()))
    );
  },

  getListFieldElementAttributesFromModel(field, element) {
    const attrs = { _id: element.id };
    return attrs;
  },

  displayNameForListFieldElement(field, element, subProperty, keyModel, plainTextOnly) {
    const displayName = plainTextOnly ? element.displayName() : element.displayNameHtml();
    return displayName;
  },
});

app.Integration.TippableTenderType = app.AbstractModel.extend({
  displayName() {
    const name = app.AbstractModel.prototype.displayName.apply(this, arguments);
    return `${name} (supports tips: ${this.get('supportsTips') ? 'yes' : 'no'})`;
  },
});
