import Backbone from 'backbone';
import _ from 'underscore';

import { Schema } from '@biteinc/common';
import { UserRightHelper } from '@biteinc/enums';
import { MathHelper, StringHelper } from '@biteinc/helpers';

import { BureauMathHelper } from '../../helpers/bureau_math_helper';
import { template } from '../../template';

app.FieldView = Backbone.View.extend({
  className() {
    return 'field-container';
  },
  template: template(`
    <label class="col-form-label col-md-4"></label>
    <div class="col-md-8 input-container collapsible">
      <i class="bi bi-check form-control-feedback"></i>
      <span class="fetching form-control-feedback"></span>
      <span class="form-text"></span>
    </div>
  `),
  _viewHasBeenAddedToDom: false,
  states: {
    INIT: 0,
    PROGRESS: 1,
    SUCCESS: 2,
    ERROR: 3,
  },

  initialize(options) {
    this.field = options.field;
    this.schema = options.schema;
    this.subProperty = options.subProperty;
    this.keyModel = options.keyModel;
    this.isReadOnly = options.isReadOnly;
    this.fieldGroupView = options.fieldGroupView;

    this.options = options;

    this._$maskedForm = null;
    this._maskedFormIsVisible = false;
  },

  destroy() {
    this.isDestroyed = true;
    this.model = null;
    if (this.autoCompleteListView) {
      this.stopListening(this.autoCompleteListView);
      this.autoCompleteListView.destroy();
    }
  },

  setState(state, message) {
    this.$el.removeClass('has-error');
    this.$el.removeClass('has-success');
    this.$el.removeClass('fetching');
    this.$('.form-text').text(message);
    this.state = state;

    switch (state) {
      case this.states.PROGRESS:
        this.$el.addClass('fetching');
        break;
      case this.states.SUCCESS:
        this.$el.addClass('has-success');
        break;
      case this.states.ERROR:
        this.$el.addClass('has-error');
        break;
    }
  },

  isPermanent() {
    if (
      this.schema.isReadOnly ||
      this.isReadOnly ||
      (this.schema.writeRight && !app.sessionUser.hasRight(this.schema.writeRight))
    ) {
      return true;
    }
    if (this.model) {
      return (
        (!this.model.isNew() && this.schema.isPermanent) ||
        this.model.fieldIsPermanent(
          this.field,
          this.subProperty,
          this.superValue || this.initialValue,
        )
      );
    }
    return !!this.schema.isPermanent;
  },

  setValue(value, model, superValue) {
    this.initialValue = value;
    if (model) {
      this.model = model;
    }
    if (superValue) {
      this.superValue = superValue;
    }

    if (
      value === undefined &&
      _.has(this.schema, 'defaultValue') &&
      this.model &&
      this.model.isNew()
    ) {
      // Don't apply default values to existing models
      // eslint-disable-next-line no-param-reassign
      value = this.schema.defaultValue;

      // Only for bools and nullable fields override initial value.
      // Otherwise, we can't get a 'false' or 'null' default value.
      if (this.schema.type === 'bool' || this.schema.isNullable) {
        this.initialValue = value;
      }
    }

    this.toggleClearButton(value);

    if (this.isPermanent()) {
      this.$form.prop('disabled', true);
    } else {
      this.$form.prop('disabled', false);
    }

    switch (this.schema.type) {
      case 'int':
        if ('float5' === this.schema.subtype) {
          this.$form.val(MathHelper.stringFromFloat5(value));
          break;
        }
      // Fall through if it's a regular int
      /* falls through */
      case 'float':
      case 'codeString':
      case 'shortString':
      case 'longString':
      case 'keyString':
      case 'stringEnum':
      case 'mongoId':
      case 'password':
      case 'email':
      case 'phone':
      case 'url':
        this.$form.val(value);
        break;
      case 'price':
        this.$form.val(MathHelper.displayPrice(value));
        break;
      case 'bool':
        this.$form.prop('checked', !!value);
        break;
    }

    switch (this.schema.type) {
      case 'shortString':
      case 'longString':
        this.toggleCollapsedView(this.schema.collapse && !(this.getValue() || '').length);
        break;
    }

    if (this._$maskedForm) {
      this._$maskedForm.val(value);
      if (!value) {
        this._toggleMaskedForm();
      }
    }

    this._prevValue = this.getValue();
  },

  setupListViewFilter(listView) {
    this.toggleClearButton(null);
    this.listView = listView;
  },

  getValue() {
    if (this.isPermanent()) {
      return this.initialValue;
    }

    switch (this.schema.type) {
      case 'int':
        if ('float5' === this.schema.subtype) {
          const float5Value = parseFloat(this.$form.val(), 10);
          if (float5Value >= -9999999 && float5Value <= 9999999) {
            return MathHelper.float5FromFloatString(this.$form.val());
          }
        } else {
          const intValue = parseInt(this.$form.val(), 10);
          if (intValue >= Schema.INT_MIN && intValue <= Schema.INT_MAX) {
            return intValue;
          }
        }
        break;
      case 'float': {
        const floatValue = parseFloat(this.$form.val(), 10);
        if (floatValue >= -9999999 && floatValue <= 9999999) {
          return floatValue;
        }
        break;
      }
      case 'price': {
        const validation = BureauMathHelper.priceFromString(
          this.$form.val().trim(),
          this.schema.canBeNegative,
        );
        if (!validation.error) {
          return validation.value;
        }
        break;
      }
      case 'bool': {
        const value = !!this.$form.prop('checked');
        if (!value && !this.initialValue) {
          return this.initialValue;
        }
        return value;
      }
      case 'phone': {
        let string = this.$form.val();
        if (_.isString(string)) {
          string = string.trim().replace(/\D/g, '');
          if (!string.length && !this.initialValue) {
            return this.initialValue;
          }
          return string;
        }
        break;
      }
      case 'shortString':
      case 'longString':
      case 'keyString':
      case 'stringEnum':
      case 'mongoId':
      case 'email':
      case 'url': {
        let string = this.$form.val();
        if (_.isString(string)) {
          string = string.trim();
          if (!string.length && !this.initialValue) {
            return this.initialValue;
          }
          return string;
        }
        break;
      }
      case 'password':
      case 'codeString': {
        const codeString = this.$form.val();
        if (_.isString(codeString)) {
          if (!codeString.length && !this.initialValue) {
            return this.initialValue;
          }
          return codeString;
        }
        break;
      }
    }

    return null;
  },

  addCollapseButton(optTitle) {
    if (this.$collapseDiv) {
      return;
    }

    if ((optTitle || '').length) {
      this.$collapseLabel = $(`<label class="col-form-label col-md-4">${optTitle}:</label>`);
      this.$el.append(this.$collapseLabel);
    }

    const $expandButton = $('<i class="expand-button bi bi-plus"></i>');
    $expandButton.click(this.toggleCollapsedView.bind(this, false));
    this.$collapseDiv = $('<div class="col-md-8 collapsed"></div>');
    this.$collapseDiv.append($expandButton);
    this.$el.append(this.$collapseDiv);
  },

  toggleCollapsedView(visible) {
    if (visible && !this.$collapseDiv) {
      return;
    }

    this.$('.collapsible').toggle(!visible);
    if (this.$collapseDiv) {
      this.$collapseDiv.toggle(visible);
    }
    if (this.$collapseLabel) {
      this.$collapseLabel.toggle(visible);
    }
  },

  isNullValid() {
    if (this.schema.isNullable) {
      if (this.initialValue === undefined) {
        return false;
      }
      return true;
    }
    return false;
  },

  /**
   * @public
   * @returns { { isValid: true } | { isValid: false, invalidFieldNames: string[] } }
   */
  checkValidity() {
    if (this.isPermanent()) {
      return { isValid: true };
    }
    this.hasValidated = true;
    const isValid = this.validateInput();
    return isValid ? { isValid } : { isValid, invalidFieldNames: [] };
  },

  /** @returns {boolean} */
  _doesContainOrExists(condition) {
    const fieldValue = condition.model
      ? app[condition.model].get(condition.field)
      : this.fieldGroupView.getValue()[condition.field];
    if (condition.exists) {
      return fieldValue !== undefined && fieldValue !== null;
    }

    if (!Array.isArray(fieldValue)) {
      return condition.values.includes(fieldValue);
    }

    // Handle empty array case
    if (
      fieldValue.length === 0 &&
      _.some(
        condition.values,
        (conditionValue) => Array.isArray(conditionValue) && conditionValue.length === 0,
      )
    ) {
      return true;
    }
    return false;
  },

  /** @returns {boolean} */
  _evaluateCondition(condition) {
    // Check if the condition is satisfied
    const containsOrExists = this._doesContainOrExists(condition);

    // Make sure we are on the correct level
    switch (condition.level) {
      case 'org':
        return !!app.org && !app.site && !app.location && containsOrExists;
      case 'site':
        return !!app.site && !app.location && containsOrExists;
      case 'location':
        return !!app.location && containsOrExists;
      default:
        return containsOrExists;
    }
  },

  /** @returns {boolean} */
  doesSatisfyAllConditions() {
    if (!this.fieldGroupView) {
      // If this field is not part of a field-group, then it can't be subject to any conditions
      return true;
    }
    // All of the andConditions need to be satisfied
    const andConditions = _.all(this.schema.conditions || [], (condition) => {
      return this._evaluateCondition(condition);
    });
    // Only one of the orConditions needs to be satisfied
    const orConditions =
      !this.schema.orConditions?.length || // Not sure why without this check, _.any returns false when the array is empty
      _.any(this.schema.orConditions, (condition) => {
        return this._evaluateCondition(condition);
      });

    return andConditions && orConditions;
  },

  /**
   * A field is only considered required if it meets all conditions
   * If the field is not being displayed to the user, then how do you expect them to fill it in?
   *
   * @returns {boolean}
   */
  isRequired() {
    return this.doesSatisfyAllConditions() && this.schema.required;
  },

  /**
   * @returns {{
   *   value: T;
   *   isValid: boolean;
   *   isEmpty: boolean;
   * }}
   */
  getValidationDetails() {
    let value = this.getValue();
    let isValid = true;
    const isEmpty = this.$form.val().toString().length === 0;
    const required = this.isRequired();

    switch (this.schema.type) {
      case 'int':
      case 'float':
      case 'price':
        if (null === value && !this.schema.isNullable) {
          isValid = !required && !this.$form.val().toString().length;
        }
        break;
      case 'phone':
        if ('' === value) {
          isValid = !required;
        } else {
          isValid = value?.replace(/\D/g, '').length === 10;
        }
        break;
      case 'codeString':
      case 'shortString':
      case 'longString':
      case 'keyString':
      case 'stringEnum':
      case 'mongoId':
      case 'password':
      case 'email':
      case 'url':
        value = value || '';
        if ('' === value) {
          isValid = !required;
        }
        break;
    }
    return { value, isValid, isEmpty };
  },

  /**
   * @protected
   */
  toggleClearButton(currentValue) {
    if (this._$clearValueButton) {
      const canBeCleared = currentValue !== null && currentValue !== undefined;
      this._$clearValueButton.toggle(canBeCleared);
      this.$inputContainer.toggleClass('has-clear-button', canBeCleared);
    }
  },

  /**
   * @protected
   * @return { boolean }
   */
  validateInput() {
    const { value, isValid, isEmpty } = this.getValidationDetails();
    const hasError = (!isValid && !isEmpty) || (!isValid && this.hasValidated);
    if (hasError) {
      this.setState(this.states.ERROR);
    } else if (this.schema.usesModelValidation && !isEmpty) {
      this.setState(this.states.PROGRESS);
      return this.model.validateField(this.field, value, (fieldIsValid, message) => {
        if (this.isDestroyed || this.getValue() !== value) {
          return;
        }

        if (fieldIsValid) {
          this.setState(this.states.SUCCESS);
        } else {
          this.setState(this.states.ERROR, message);
        }
      });
    } else {
      this.setState(this.states.INIT);
    }
    this.toggleClearButton(value);

    return isValid;
  },

  viewWasAddedToDom() {
    this._viewHasBeenAddedToDom = true;
  },

  getDisplayName(forPlaceholder) {
    let displayName = this.schema.displayName || StringHelper.toTitleCase(this.field);

    if (!forPlaceholder) {
      if (this.schema.biteOnly) {
        displayName += `&nbsp;${app.HtmlHelper.biteRightIcon}&nbsp;`;
      } else if (
        this.schema.writeRight &&
        UserRightHelper.isResellerRight(this.schema.writeRight)
      ) {
        displayName += `&nbsp;${app.HtmlHelper.resellerRightIcon}&nbsp;`;
      }

      if (this.schema.required && !this.schema.isNullable) {
        displayName = `* ${displayName}`;
      }
    }

    return displayName;
  },

  onFieldChange() {
    const val = this.getValue();
    if (val !== this._prevValue) {
      this.validateInput();
      this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
    }
    this._prevValue = val;
    return true;
  },

  shouldMaskInput() {
    if (!this.schema.maskInput) {
      return false;
    }

    switch (this.schema.type) {
      case 'codeString':
      case 'email':
      case 'keyString':
      case 'longString':
      case 'shortString':
        // Don't mask values in new models
        const model = this.fieldGroupView?.model;
        return !model || !model.isNew();
      default:
        return false;
    }
  },

  renderField() {
    switch (this.schema.type) {
      case 'int':
      case 'float':
      case 'price':
        this.$form = $('<input type="number" />');
        break;
      case 'shortString':
        this.$form = $('<input type="text" />');
        if (this.field === 'name') {
          this.$form.prop('autocapitalize', 'words');
        }
        break;
      case 'phone':
        this.$form = $('<input type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />');
        break;
      case 'keyString':
      case 'mongoId':
      case 'url':
        this.$form = $('<input autocomplete="off" autocapitalize="off" type="text" />');
        break;
      case 'password':
        this.$form = $('<input autocomplete="new-password" type="password" />');
        break;
      case 'codeString':
        this.$form = $('<textarea class="code-string" type="text" />');
        break;
      case 'longString':
        this.$form = $('<textarea type="text" />');
        break;
      case 'email':
        this.$form = $('<input type="email" />');
        break;
      case 'bool':
        this.$form = $('<input type="checkbox" class="form-check-input" />');
        break;
    }

    // Collapse textareas to a single line until they are focused
    if ('longString' === this.schema.type) {
      this.$form.toggleClass('no-content', true);
      this.$form.focus(() => {
        this.$form.toggleClass('no-content', false);
      });
      this.$form.blur(() => {
        this.$form.toggleClass('no-content', true);
      });
    }

    if (this.$form) {
      this.$inputContainer.append(this.$form);

      this.$form.addClass('form-control');
      this.$form.attr('placeholder', this.schema.placeholder || this.getDisplayName(true));

      if ('bool' === this.schema.type) {
        this.$form.click(this.onFieldChange.bind(this));
      } else {
        this.$form.keyup(this.onFieldChange.bind(this));
        // Listen for changes to the input field done through browser-controls
        // (e.g. side arrows on the input[type=number], or pressing up/down)
        this.$form.change(this.onFieldChange.bind(this));
      }
    }

    if (this.schema.isNullable && !this.isReadOnly) {
      this._$clearValueButton = $(`
        <button type="button" class="btn btn-secondary clear-button">
          <i class="bi bi-trash3" aria-hidden="true" alt="Clear value"></i>
        </button>`);
      this.$inputContainer.append(this._$clearValueButton);
      this._$clearValueButton.click(() => {
        this.setValue(null);
        this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
      });
      // The button will be shown in setValue depending on whether there's a value to clear.
      this._$clearValueButton.hide();
    }
  },

  _toggleMaskedForm() {
    this._maskedFormIsVisible = !this._maskedFormIsVisible;
    this.$form.toggle(!this._maskedFormIsVisible);
    this._$maskedForm.toggle(this._maskedFormIsVisible);
    this._$toggleMaskButtonIcon.toggleClass('bi-eye-fill', this._maskedFormIsVisible);
    this._$toggleMaskButtonIcon.toggleClass('bi-eye-slash-fill', !this._maskedFormIsVisible);
  },

  render() {
    this.$el.html(this.template());
    this.$label = this.$el.find('label');
    this.$inputContainer = this.$el.find('.input-container');
    this.$el.attr('id', this.field);

    this.$label.html(`${this.getDisplayName()}:`);

    if (this.schema.tooltip) {
      this.$label.prepend(app.renderTooltip(this.schema.tooltip));
    }

    this.renderField();

    if (this.shouldMaskInput()) {
      this._$maskedForm = $('<input class="form-control" disabled="true" type="password" />');
      this.$inputContainer.append(this._$maskedForm);

      this._$toggleMaskButton = $(`
        <button type="button" class="btn btn-secondary toggle-input-mask-button">
          <i class="bi bi-eye-fill toggle-mask-icon" aria-hidden="true" alt="Toggle Input Mask"></i>
        </button>`);
      this._$toggleMaskButtonIcon = this._$toggleMaskButton.find('.toggle-mask-icon');
      this.$inputContainer.append(this._$toggleMaskButton);
      this.$inputContainer.addClass('has-toggle-input-mask-button');
      this._$toggleMaskButton.click(() => {
        this._toggleMaskedForm();
      });

      this._toggleMaskedForm();
    }

    if (this.schema.collapse) {
      this.addCollapseButton();
    }

    if (this.schema.autoCompleteUrl) {
      const collection = new app.AutoCompleteList([], {
        resultModel: app[this.schema.autoCompleteModel] || app.Spirit,
        url: this.schema.autoCompleteUrl,
      });
      if (this.autoCompleteListView) {
        this.stopListening(this.autoCompleteListView);
        this.autoCompleteListView.destroy();
      }
      this.autoCompleteListView = new app.AutoCompleteListView({
        collection,
        $input: this.$form,
      });
      this.$inputContainer.append(this.autoCompleteListView.render().el);
      this.listenTo(this.autoCompleteListView, 'autoCompleteItemWasClicked', (result) => {
        this.setValue(result.result.get(this.field));
        const e = app.FieldView.Events.FieldWasAutoCompleted;
        this.trigger(e, this, result.result);
      });
    }
    const $warning = $('<div class="warning"></div>');
    if (this.schema.warningHtml) {
      this.$inputContainer.append($warning.html(this.schema.warningHtml));
    }

    return this;
  },
});

app.FieldView.Events = {
  FieldWasAutoCompleted: 'fieldWasAutoCompleted',
  FieldDidChangeValue: 'FieldDidChangeValue',
};
