import _ from 'underscore';

app.NestedObjectFieldView = app.FieldView.extend({
  className() {
    return `${app.FieldView.prototype.className.apply(this, arguments)} nested-object`;
  },

  getNestedObjectDefaultValue() {
    if (!this.schema.fields) {
      throw new Error('this method needs to be overridden in a subclass');
    }

    if ('defaultValue' in this.schema) {
      return this.schema.defaultValue;
    }

    const defaultValue = {};
    _.each(this.schema.fields, (fieldSchema, fieldName) => {
      if (fieldSchema.defaultValue) {
        defaultValue[fieldName] = fieldSchema.defaultValue;
        return;
      }
      switch (fieldSchema.type) {
        case 'array':
          defaultValue[fieldName] = [];
          break;
        case 'email':
        case 'keyString':
        case 'mongoId':
        case 'phoneNumber':
          defaultValue[fieldName] = '';
          break;
        case 'float':
        case 'int':
          defaultValue[fieldName] = 0;
          break;
        default:
          throw new Error(`unsupported field type: ${fieldSchema.type}`);
      }
    });
    return defaultValue;
  },

  getNestedObjectSchema() {
    if (this.schema.fields) {
      return this.schema;
    }
    throw new Error('this method needs to be overridden in a subclass for custom schemas');
  },

  getValue() {
    const value = this.nestedFieldGroupView.getValue();
    if (!Object.keys(value).length) {
      if (!this.initialValue) {
        return this.initialValue;
      }
      return this.getNestedObjectDefaultValue();
    }
    return value;
  },

  setValue(value, model, superValue) {
    this.initialValue = value;
    if (model) {
      this.model = model;
    }
    if (superValue) {
      this.superValue = superValue;
    }

    this.nestedFieldGroupView.setValue(value || {}, model);
  },

  deleteValue() {
    // If we had something set then we just want to null it out.
    // If we didn't (i.e. we start with undefined/null, then add a tip, don't save, then delete it),
    // then we want to return to the exact initial value.
    this.setValue(this.initialValue ? null : this.initialValue);
  },

  isNullValid() {
    return !!this.schema.isNullable || !this.isRequired();
  },

  validateInput() {
    return this.nestedFieldGroupView.checkValidity().isValid;
  },

  checkValidity() {
    if (this.initialValue || Object.keys(this.nestedFieldGroupView.getValue()).length) {
      return this.nestedFieldGroupView.checkValidity();
    }

    if (this.isNullValid()) {
      return {
        isValid: true,
      };
    }

    return {
      isValid: false,
      invalidFieldNames: [this.field],
    };
  },

  render() {
    if (!this.nestedFieldGroupView) {
      this.nestedFieldGroupView = new app.FieldGroupView({
        schema: this.getNestedObjectSchema(),
        isReadOnly: this.isReadOnly || this.schema.isReadOnly,
        subProperty: this.subProperty ? `${this.subProperty}.${this.field}` : this.field,
      });
      this.$el.append(this.nestedFieldGroupView.render().el);
    }

    const title = new DOMParser()
      .parseFromString(this.getDisplayName(), 'text/html')
      .documentElement.textContent.trim();
    this.$el.attr('title', title);

    this.listenTo(
      this.nestedFieldGroupView,
      app.FieldGroupView.Events.FieldGroupDidChangeValue,
      () => {
        this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
      },
    );

    // Necessary because FieldGroupView has some fieldGroups with upload views.
    // also because some fields are set asynchronously like this.model etc, this.initialValue
    setTimeout(() => {
      if (!this._$clearValueButton && this.schema.isNullable && !this.isReadOnly) {
        this._$clearValueButton = $(`
          <button type="button" class="btn btn-secondary clear-button">
            <i class="bi bi-trash3" aria-hidden="true" alt="Clear value"></i>
          </button>`);
        this.$el.prepend(this._$clearValueButton);
        this._$clearValueButton.on('click', () => {
          this.deleteValue();
          this.trigger(app.FieldView.Events.FieldDidChangeValue, this);
          this.render();
        });
      }

      this.nestedFieldGroupView.$el.show();
      this._$clearValueButton?.show();
      this.$addButton?.hide();

      this.nestedFieldGroupView.viewWasAddedToDom();
    }, 1);

    return this;
  },
});
